import { MatchParams, UserEnv } from '@/types/match'
import { PageType, StaticPageSlug, StaticPageSlugForProject } from '@/types/pageType'

const getEnv = (slugs: string[]): UserEnv => {
  if (slugs[0] === 'portal') {
    return 'open'
  }
  if (slugs[1] === 'closed') {
    return 'closed'
  }
  return 'open'
}

export const parseRouteParams = (
  slugOrSlugs?: string | string[]
): { pageType: PageType; params: MatchParams } => {
  const slugs = typeof slugOrSlugs === 'string' ? [slugOrSlugs] : slugOrSlugs

  if (!slugs || slugs.length === 0) {
    return {
      pageType: PageType.page,
      params: { projectSlug: 'portal' },
    }
  }
  if (Object.values(StaticPageSlug).includes(slugs[0] as StaticPageSlug)) {
    return {
      pageType: PageType.static,
      params: { projectSlug: 'portal' },
    }
  }
  if (Object.values(StaticPageSlugForProject).includes(slugs[1] as StaticPageSlugForProject)) {
    return {
      pageType: PageType.static,
      params: { projectSlug: slugs[0] },
    }
  }
  const baseParams: MatchParams = {
    projectSlug: slugs[0],
    env: getEnv(slugs),
  }
  if (slugs[2] === 'article') {
    return {
      pageType: PageType.article,
      params: {
        ...baseParams,
        articleSlug: slugs[3],
      },
    }
  }
  if (slugs[2] === 'housetype') {
    return {
      pageType: PageType.houseType,
      params: {
        ...baseParams,
        houseTypeSlug: slugs[3],
      },
    }
  }
  if (slugs[2] === 'sectionpage') {
    return {
      pageType: PageType.footer,
      params: {
        ...baseParams,
        footerPageSlug: slugs[3],
      },
    }
  }
  if (slugs[2] === 'homestudio' && !!slugs[3]) {
    return {
      pageType: PageType.wishList,
      params: {
        ...baseParams,
        wishListId: slugs[3],
        wishListItem: slugs[4],
      },
    }
  }
  if (slugs[2] === 'password' && slugs[3] === 'reset') {
    return {
      pageType: PageType.password,
      params: {
        ...baseParams,
        token: slugs[4],
      },
    }
  }
  return {
    pageType: PageType.page,
    params: {
      ...baseParams,
      menuSlug: slugs[2],
      pageSlug: slugs[3],
    },
  }
}
