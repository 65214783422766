import { IFormOption } from '@/entities/legacyTypes'
import { ChangeEvent, useCallback } from 'react'
import Text from '@/atoms/Text'
import InputText from '@/components/shared/forms/fieldTypes/Input'
import Select from '@/components/shared/forms/fieldTypes/Select'
import TextArea from '@/components/shared/forms/fieldTypes/Textarea'
import ChoiceGroup from '@/components/shared/forms/fieldTypes/ChoiceGroup'
import RadioGroup from '@/components/shared/forms/fieldTypes/RadioGroup'
import { type InputValue } from '@/components/formWizard/FormWizardSliderModal'
import useIsFormFieldValid from '@/hooks/useIsFormFieldValid'
// eslint-disable-next-line import/no-cycle
import BBCode from '@/components/shared/richText/BBCode'
import Users from '@/components/shared/forms/fieldTypes/Users'
import CheckboxBoolean from '@/components/shared/forms/fieldTypes/CheckboxBoolean'
import PasswordLink from '@/components/shared/forms/fieldTypes/PasswordLink'

export const PROJECT_UPDATE_NOTIFICATIONS = 'projectUpdateNotifications'
export const ARTICLE_NOTIFICATIONS = 'articleNotifications'

type Props = {
  onChange: (n: InputValue) => void
  onValidation: (bool: boolean) => void
  value: InputValue
} & IFormOption

const FormWizardFieldGenerator = ({ onChange, onValidation, value, ...props }: Props) => {
  const { type, name, required, choices, label, title, id, defaultChecked, maxLength } = props

  const { valid, message, setValidity } = useIsFormFieldValid()
  const changeHandler = useCallback(
    <T extends HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(e: ChangeEvent<T>) => {
      onValidation(e.target.validity.valid)
      setValidity(e.target.validity)
      onChange(e.target.value)
    },
    [onChange, onValidation, setValidity]
  )

  switch (type) {
    case 'text':
    case 'email':
    case 'password':
    case 'number':
    case 'tel':
    case 'url':
    case 'bamDate':
      return (
        <InputText
          maxLength={type === 'bamDate' ? 10 : maxLength || undefined}
          id={id}
          validationMessage={message}
          isValid={valid}
          type={type}
          title={title}
          className="filled"
          name={id}
          defaultValue={value as string}
          onChange={changeHandler}
          required={required}
        />
      )
    case 'textarea':
      return (
        <TextArea
          id={id}
          name={id}
          required={required}
          title={title}
          validationMessage={message}
          isValid={valid}
          onChange={changeHandler}
        />
      )

    case 'select':
      if (!choices) {
        return null
      }
      return (
        <Select
          required={required}
          name={name}
          title={title}
          choices={choices}
          validationMessage={message}
          isValid={valid}
          defaultValue={value as string}
          onChange={changeHandler}
          hasPlaceholder
        />
      )

    case 'choice':
    case 'choicegroup':
    case 'radio':
    case 'checkbox':
      if (!choices && type !== 'checkbox') {
        return null
      }
      return (
        <ChoiceGroup
          label={label ?? ''}
          title={title}
          // @ts-expect-error
          choices={choices}
          type={type}
          required={required}
          name={name}
          isValid={valid}
          validationMessage={message}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onValidation(e.target.validity.valid)
            setValidity(e.target.validity)
            if (
              e.target.id !== ARTICLE_NOTIFICATIONS &&
              e.target.id !== PROJECT_UPDATE_NOTIFICATIONS
            ) {
              if (e.target.getAttribute('data-group-id')) {
                const choiceGroupCheckboxes = document.querySelectorAll(
                  `[data-group-id="${e.target.getAttribute('data-group-id')}"]:checked`
                )

                const choiceGroupValues = Array.from(
                  choiceGroupCheckboxes,
                  (checkbox) => (checkbox as HTMLInputElement | null)?.value
                )
                onChange(choiceGroupValues as string[])
                return
              }
              onChange(!!e.target.checked)
              return
            }
            if (
              e.target.type === 'checkbox' &&
              (e.target.id === ARTICLE_NOTIFICATIONS ||
                e.target.id === PROJECT_UPDATE_NOTIFICATIONS)
            ) {
              onChange(e.target.checked === false ? 0 : 1)
              return
            }
            onChange(e.target.value)
          }}
          defaultChecked={defaultChecked}
        />
      )
    case 'radiogroup':
      if (!choices) {
        return null
      }
      return (
        <RadioGroup
          // @ts-expect-error
          onChange={changeHandler}
          id={id}
          choices={choices}
          name={name}
          label={title}
          required={required}
          title={title}
          value={value}
          type={type}
          isValid={valid}
          validationMessage={message}
          defaultValue={value as string}
        />
      )

    case 'readOnly':
      return (
        <BBCode
          content={{
            content: label || '',
          }}
        />
      )
    case 'users':
      return <Users {...props} />
    case 'passwordLink':
      return <PasswordLink {...props} />
    case 'checkboxBoolean':
      return <CheckboxBoolean {...props} />
    default:
      return <Text>Dit formulier element wordt nog niet ondersteund.</Text>
  }
}

export default FormWizardFieldGenerator
